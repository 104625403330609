var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: { close: _vm.reloadPage, "click:outside": _vm.reloadPage },
      }),
      _vm.api.isLoading && _vm.isFirstLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("v-data-table", {
            attrs: {
              items: _vm.data.data,
              lazy: true,
              loading: _vm.api.isLoading,
              "show-expand": "",
              options: _vm.options,
              "items-per-page:sync": _vm.options.itemsPerPage,
              headers: _vm.dataHeader,
              "server-items-length": _vm.data.total,
              "sort-desc:sync": _vm.options.sortDesc,
              "sort-by": _vm.options.sortBy,
              "item-key": "user_id",
              expanded: _vm.expanded,
              "footer-props": {
                itemsPerPageOptions: [20, 40, 100],
                showCurrentPage: true,
              },
              dense: "",
            },
            on: {
              "update:options": [
                function ($event) {
                  _vm.options = $event
                },
                _vm.onPaginationUpdate,
              ],
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.options, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.options, "sortBy", $event)
              },
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.index",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.options.page - 1) * _vm.options.itemsPerPage +
                              (_vm.data.data.indexOf(item) + 1)
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "text-h4 pt-4",
                        attrs: { flat: "", height: "auto" },
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "mx-4" },
                          [
                            _c("v-row", { attrs: { "no-gutters": "" } }, [
                              _vm._v(" Prototype Company Report "),
                            ]),
                            _c(
                              "v-row",
                              { staticClass: "pt-3" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "mt-2" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        "item-text": "coupon",
                                        "item-value": "coupon",
                                        items: _vm.coupons,
                                        label: "Filter Coupon",
                                        dense: "",
                                        outlined: "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.options.couponSearch,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.options,
                                            "couponSearch",
                                            $$v
                                          )
                                        },
                                        expression: "options.couponSearch",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {},
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pt-0" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        label: "Search",
                                        outlined: "",
                                        clearable: "",
                                      },
                                      on: { input: _vm.handleSearchInput },
                                      model: {
                                        value: _vm.search,
                                        callback: function ($$v) {
                                          _vm.search = $$v
                                        },
                                        expression: "search",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", { staticClass: "pt-0" }, [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-end" },
                                    [
                                      _c(
                                        "JsonCSV",
                                        {
                                          attrs: {
                                            data: _vm.downloadData,
                                            name: _vm.name,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mx-2",
                                              attrs: {
                                                loading:
                                                  _vm.isLoadingDownloadData,
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v(" Download Report ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "expanded-item",
                fn: function (ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: headers.length } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-2 pa-2" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c(
                                  "ComponentPrototypeCompanySubscriptionHistoryTabs",
                                  { attrs: { companyId: item.company_id } }
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("ComponentCompanyAAE", {
                                  attrs: { companyId: item.company_id },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-2 pa-2" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("ComponentPrototypeCompanySpecialRemark", {
                                  attrs: { companyId: item.company_id },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("ComponentPrototypeCompanySales", {
                                  attrs: { companyId: item.company_id },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-2 pa-2" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("ComponentPrototypeCompanyHRDC", {
                                  attrs: { companyId: item.company_id },
                                }),
                                _c("hr", {
                                  staticStyle: { border: "2px solid #F2F3F7" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("ComponentPrototypeCompanyRefund", {
                                  attrs: { companyId: item.company_id },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "pa-2 ma-2" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("ComponentPrototypeCompanyFunnel", {
                                  attrs: { companyId: item.company_id },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c(
                                  "ComponentPrototypeCompanyClientInvoiceRecords",
                                  { attrs: { companyId: item.company_id } }
                                ),
                                _c("hr", {
                                  staticStyle: { border: "2px solid #F2F3F7" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.company_name",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "PageCompaniesDetail",
                            params: { id: item.company_id },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.company_name) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.bonus_offer",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.bonus_offer != null
                      ? _c("span", [
                          _vm._v(" " + _vm._s(item.bonus_offer) + " "),
                        ])
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "item.user_created_at",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.user_created_at != null
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.convertTimeZone(item.user_created_at)
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "item.welcome_guide_sent",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.welcome_guide_sent != null
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.convertTimeZone(item.welcome_guide_sent)
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "item.welcome_wa_sent",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.welcome_wa_sent != null
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.convertTimeZone(item.welcome_wa_sent)
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "item.user_status",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      [
                        item.user_status == 1
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "100",
                                  small: "",
                                  color: "green white--text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.activateAccountStatus(
                                      item.user_id,
                                      0
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Active ")]
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "100",
                                  small: "",
                                  color: "red white--text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.activateAccountStatus(
                                      item.user_id,
                                      1
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Deactive ")]
                            ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.action",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(" Actions "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-chevron-up "),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  _vm._l(_vm.actions, function (data, index) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: index,
                                        staticStyle: { "font-size": "16px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.executeActions(
                                              data.action,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(data.name) + " ")]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.email_verified_at",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      [
                        item.email_verified_at != null
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "100",
                                  small: "",
                                  color: "green white--text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.activateEmailVerification(
                                      item.user_id,
                                      0
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Active ")]
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "100",
                                  small: "",
                                  color: "red white--text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.activateEmailVerification(
                                      item.user_id,
                                      1
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Deactive ")]
                            ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }